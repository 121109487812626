.list-all-by-instance-pagination-rt{
    overflow-x: auto;

    .pagination{
        float: right;
        margin-top: 10px;
		margin-bottom: 10px;
        margin-right: 10px;
		>div{
			float: right;
		}
    }

    th{
		text-align: center;
	}

    .text-table {
        @media (max-width: 1920px) {
            max-width: 50ch;
        }

        @media (max-width: 1650px) {
            max-width: 25ch;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    abbr {
        text-decoration: none;
        cursor: default;
    }
}