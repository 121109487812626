@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.medical-certificate-list-rt {
    @include componentDefaultFonts();

    // fix css revex
    :global(.panel-rt) {
        :global(.panel-rt-header) {
            :global(.panel-rt-actions) {
                button {
                    line-height: 0;
                }
            }
        }
    }

    .medical-certificate-date-input {
        margin-top: -7px;
    }

    .medical-certificate-pagination {
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;

        >div {
            float: right;
        }

        :last-child {
            margin-top: -6px;
        }
    }

    th {
        text-align: center;
    }

    .medical-certificate-td {
        white-space: unset;
    }

    .modal-show-photo {
        text-align: center;
    }

    .medical-certificate-options {
        margin-top: 5px;
    }

    .button-actions {
        text-align: center;
        margin-top: 10px;

        .last-button {
            margin-left: 5px;
        }
    }
}