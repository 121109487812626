.table-list-pagination-rt{
	overflow-x: auto;
	
	.actions{
		float: right;
		margin-bottom: 10px;
		>div{
			float: right;
		}
	}
}
