@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.employee-situation-new-rt{
    @include componentDefaultFonts();

    :global(.switch-rt){
        margin: 10px auto 25px auto;
    }

    .group-buttons{
        text-align: right;
        margin: 5px 5px 5px auto;

        .button-action{
            &:first-child{
                margin-right: 10px;
            }
        }
    }

    .form-employee-situation{
        margin-top: 10px;

        :global(.datetime-picker-content){
            z-index: 11;
        }

        :global(.radio-rt){
            margin: 10px auto auto auto;
        }

        // :global(input){
        //     margin: 15px auto 15px auto;
        // }
    }
}