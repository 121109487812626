@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.medical-certificate-new-rt {
    @include componentDefaultFonts();

    :global(.switch-rt) {
        margin-top: 10px;
        margin-bottom: 5px;
        z-index: 0;
        position: relative;
    }

    .select-photo {
        margin-top: 10px;
    }

    .button-actions {
        text-align: right;
        margin-top: 10px;

        .last-button {
            margin-left: 5px;
        }
    }
}