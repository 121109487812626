@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.employee-situation-edit-rt{
    @include componentDefaultFonts();

    .group-buttons{
        text-align: right;
        margin: 15px 5px 5px auto;

        .button-action{
            &:first-child{
                margin-right: 10px;
            }
        }
    }

    .form-employee-situation{
        margin-top: 10px;

        :global(.datetime-picker-content){
            z-index: 11;
        }

        :global(.radio-rt){
            margin: 10px auto auto auto;
        }
    }

    .daily-hours-edit-scale{
        margin-top: 10px;
    }
}