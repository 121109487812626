@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.situation-types-list-rt{
	@include componentDefaultFonts();

	.situation-types-list-rt-actions{
		@include clearfix();
		margin-right: 10px;
		margin-bottom: 10px;
		.button-rt-new-situation{
			float: right;
			margin-right: 10px;
		}
	}

	.table-item-action {
		margin-right: 10px;
	}

	.button-action{
		float: right;
	}

	.text-table{
		@media (max-width: 1920px) {
            max-width: 50ch;
        }

        @media (max-width: 1650px) {
            max-width: 25ch;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
	}

	abbr{
		text-decoration: none;
		cursor: default;
	}

	:global(label){
		line-height: 25px;
	}

	:global(.modal-new-rt .modal-new-rt-container .modal-new-rt-body){
        overflow-y: unset;
    }

	.bold{
		font-weight: bold;
	}
}