@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.employee-situation-list-employee-rt {
    @include componentDefaultFonts();
    overflow-x: auto;

    .panel-list-employee-situation {

        // fix css revex
        button {
            line-height: 0;
        }
    }

    .button-add {
        float: right;
        margin-right: 10px;
        margin-top: -40px;
    }

    .pagination {
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;

        >div {
            float: right;
        }
    }

    .text-table {
        @media (max-width: 1920px) {
            max-width: 50ch;
        }

        @media (max-width: 1650px) {
            max-width: 25ch;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    th{
		text-align: center;
	}

    abbr {
        text-decoration: none;
        cursor: default;
    }

    .button-action {
        &:first-child {
            margin-right: 10px;
        }
    }

    .modal-delete-employee-situation {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }

    :global(.modal-new-rt .modal-new-rt-container .modal-new-rt-body) {
        overflow-y: unset;
    }
}