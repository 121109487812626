@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.employee-situation-list-instance-rt{
    @include componentDefaultFonts();

    .button-report{
        float: right;
        margin-right: 10px;
        margin-top: -35px;
    }

    :global(.select-rt){
        margin-bottom: 7px;
    }
}