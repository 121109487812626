@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.situation-types-rt-new{
	@include componentDefaultFonts();

	.form-situation-type-new{
		:global(.radio-rt){
            margin-top: 10px;
        }
		.form-option-workdays-only, .form-option-off-scale, .form-option-delete-compensation-date {
			margin-top: 10px;
		}

		.form-select-event-type {
			z-index: 11;
		}

		.form-situation-type-new-actions{
			text-align: right;
            margin-top: 10px;

            .btn-rt{
				margin-top: 5px;
                &:first-child{
                    margin-right: 10px;
                }
            }
		}
	}
}
